import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				КодМайстер
			</title>
			<meta name={"description"} content={"Ми допоможемо вам опанувати сучасні технології та навички, необхідні для успішної кар'єри у сфері IT. "} />
			<meta property={"og:title"} content={"КодМайстер"} />
			<meta property={"og:description"} content={"Ми допоможемо вам опанувати сучасні технології та навички, необхідні для успішної кар'єри у сфері IT. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header>
		<Section background="--color-dark" padding="80px 0 0 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline3"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Контактна інформація
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Маєте запитання? Зв'яжіться з нами за телефоном або електронною поштою, і ми з радістю допоможемо вам. Наші контактні дані вказані нижче.
				</Text>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					вулиця Кирилівська, 134а офіс 231,{" "}
					<br />
					3й поверх, Київ, 04073
					<br />
					<br />
					0503390556
					<br />
					<br />
					info@luminecto.com
				</Text>
			</Box>
		</Section>
		<Section
			padding="120px 0 120px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/programming-language_1160-100052401-orig-001_i.jpg?v=2024-06-18T12:51:15.602Z) center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline4"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
					text-transform="uppercase"
				>
					Не впевнені, який курс вибрати? Замовте безкоштовну консультацію з нашим інструктором, який допоможе вам визначити ваші цілі та підібрати найкращий курс для вас.
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1">
				вулиця Кирилівська, 134а офіс 231,{" "}
				<br />
				3й поверх, Київ, 04073
				<br />
				<br />
				0503390556
				<br />
				<br />
				info@luminecto.com
			</Override>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});